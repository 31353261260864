<template>
  <CRow>
    <CCol :col="{ size: 12, order: 2 }" :lg="{ size: 9, order: 1 }">
      <CCard>
        <CCardHeader class="d-flex align-items-center">
          <TMessage v-if="!titleEditing" content="Detail" bold>
            <template>
              <div class="d-flex">
                <CBadge color="info" class="d-flex my-auto">
                  #<TMessageText :value="detail.id" />
                </CBadge>
                <TMessageText :value="detail.title" class="h5 ml-1 my-auto" />
                <CBadge color="success" class="my-auto ml-1">
                  <TMessage content="Seen" />
                </CBadge>
              </div>
            </template>
          </TMessage>
          <div v-else class="d-flex w-100 mr-2">
            <TInputText
              :value="detail.title"
              class="w-100"
              @update:value="titleUpdate = $event"
            />
            <TButtonSave
              @click="
                updateSupport('title', titleUpdate).finally(() => {
                  titleEditing = false;
                })
              "
            />
            <TButtonClose @click="titleEditing = false" />
          </div>
          <CBadge
            v-if="detail.closed_at"
            class="ml-auto"
            color="danger"
            style="font-size: 100%"
          >
            <TMessage content="Closed" />
          </CBadge>
          <TSpinner :loading="loading" />
          <TButtonEdit
            v-if="!closed && !titleEditing && checkCreator"
            variant="outline"
            class="ml-auto"
            @click="titleEditing = true"
          />
        </CCardHeader>
        <CCardBody>
          <div class="d-flex mb-2">
            <TMessageText
              :value="detail.creator ? detail.creator.name : detail.creator_id"
              class="ml-2"
              :messageOptions="{ bold: true }"
            />
            <TMessage content="created at" class="mx-1" />
            <TMessageDateTime :content="detail.created_at" />
          </div>
          <div class="mb-4 d-flex ml-2">
            <TMessage content="comments" bold>
              <template #prefix>
                {{ detail.support_contents_count }}
              </template>
            </TMessage>
            <span class="mx-2">-</span>
            <TMessage content="people read" bold>
              <template #prefix>
                {{ calViewers }}
              </template>
            </TMessage>
          </div>
          <div class="d-flex mb-2">
            <div class="d-flex mt-1" style="min-width: 40px">
              <CIcon name="cisCommentBubble" size="lg" class="mx-auto" />
            </div>
            <CCard class="w-100 ml-2 mb-2" borderColor="info">
              <CCardHeader class="d-flex" color="info" textColor="white">
                <TMessage content="Description" bold />
              </CCardHeader>
              <CCardBody>
                <TMessageTextarea
                  :editable="!closed && checkCreator"
                  :value="detail.description"
                  @change="updateSupport('description', $event)"
                />
              </CCardBody>
            </CCard>
          </div>

          <div v-for="support in contents" :key="support.id" class="d-flex">
            <div
              class="rouded rounded-circle d-flex mt-1"
              style="min-width: 40px; height: 40px"
              :class="
                support.sender ? getColor(support.sender.name) : 'bg-dark'
              "
            >
              <TMessage
                class="m-auto text-white"
                bold
                :content="
                  support.sender
                    ? support.sender.name.charAt(0).toUpperCase()
                    : '...'
                "
                noTranslate
              />
            </div>
            <CCard class="w-100 ml-2">
              <CCardHeader class="d-flex" color="light">
                <TMessage
                  :content="
                    support.sender ? support.sender.name : support.sender_id
                  "
                  noTranslate
                  bold
                  color="dark"
                />
                <TMessageDateTime
                  :content="support.created_at"
                  dateOnly
                  class="ml-2"
                />
              </CCardHeader>
              <CCardBody>
                <TMessageText
                  v-if="
                    (!support.content && !support.attachment_url) ||
                    (support.content && support.attachment_url) ||
                    (support.content && !support.attachment_url)
                  "
                  editable
                  @change="updateSupportContent(support.id, 'content', $event)"
                  :value="support.content"
                />
                <TImg
                  v-if="
                    support.attachment_url &&
                    checkMimeType(support.attachment_info)
                  "
                  class="p-0 mt-2"
                  style="width: 250px"
                  :src="getUrl(support.attachment_url)"
                  showFull
                />
                <TLink
                  v-if="
                    support.attachment_url &&
                    !checkMimeType(support.attachment_info)
                  "
                  :content="support.attachment_url"
                  :href="getUrl(support.attachment_url)"
                />
              </CCardBody>
              <div v-show="paginate.last > 1" class="mt-2">
                <TPagination store="helper.support_contents" />
              </div>
            </CCard>
          </div>
        </CCardBody>
        <CCardFooter v-if="!closed">
          <div class="d-flex">
            <div class="d-flex mt-1" style="min-width: 40px">
              <CIcon name="cisSpeechNotes" size="lg" class="mx-auto" />
            </div>
            <div class="w-100 ml-2">
              <TInputTextarea
                class="w-100"
                :value.sync="feedback"
                placeholder="Add new feedback"
                style="height: 70px"
              />
              <TInputFile
                :value="file"
                @update:value="updateFile"
                :multiple="false"
                accept="image/*,.pdf,.xlsx"
                class="w-100 mt-4"
              />
              <TButton
                @click="create"
                :options="{
                  disabled: disabled,
                }"
                content="Send"
                size="lg"
                class="px-5 mt-3"
              />
            </div>
          </div>
        </CCardFooter>
      </CCard>
    </CCol>
    <CCol :col="{ size: 12, order: 1 }" :lg="{ size: 3, order: 2 }" :order="{}">
      <CCard>
        <CCardHeader class="d-flex align-items-center p-3">
          <TMessage content="Info" bold />
        </CCardHeader>
        <CCardBody>
          <div class="mb-3">
            <TMessage content="Support type" bold class="mb-2" />
            <CBadge color="info" style="font-size: 95%">
              <TMessageText
                :value="
                  detail.support_type
                    ? detail.support_type.name
                    : detail.type_id
                "
                @click-edit="infoEditing = true"
              />
            </CBadge>
            <TMessage content="Deadline" bold class="mb-2 mt-3" />
            <TMessageDateTime
              label="Deadline"
              :content="detail.deadline"
              placeholder="Deadline"
              dateOnly
              :editable="!closed"
              @change="updateSupport('deadline', $event)"
            />
          </div>
        </CCardBody>
        <CCardFooter>
          <TButton
            v-if="!infoEditing"
            :content="closed ? 'Open' : 'Close'"
            :icon="closed ? 'cil-lock-unlocked' : 'cil-lock-locked'"
            :color="closed ? 'success' : 'danger'"
            size="lg"
            class="w-100"
            :options="{ disabled: actionLoading }"
            @click="actionSupport(closed ? 'open' : 'close')"
          />
        </CCardFooter>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      file: [],
      titleUpdate: "",
      typeUpdate: "",
      deadlineUpdate: "",
      titleEditing: false,
      infoEditing: false,
      actionLoading: false,
      feedback: "",
    };
  },
  watch: {
    infoEditing(edit) {
      if (edit) {
        this.typeUpdate = this.detail.type_id;
        this.deadlineUpdate = this.detail.deadline;
      }
    },
    checkUserSeen(isTrue) {
      if (!isTrue) {
        this.$store.dispatch("helper.supports.detail.mark-as-read");
      }
    },
  },
  created() {
    if (this.id) {
      this.$store.dispatch("helper.supports.detail.fetch", this.id).then(() => {
        this.fetchContents();
      });
    }
  },
  destroyed() {
    this.$store.commit("helper.supports.detail.purge");
    this.$store.commit("helper.support_contents.purge");
  },
  computed: {
    ...mapGetters({
      detail: "helper.supports.detail",
      loading: "helper.supports.detail.loading",
      contents: "helper.support_contents.list",
      loading_contents: "helper.support_contents.loading",
      paginate: "helper.support_contents.paginate",
    }),
    id() {
      return this.$route.params.id;
    },
    closed() {
      return !!this.detail.closed_at;
    },
    viewers() {
      return this.detail.logs?.filter((x) => x.type_id == "read") || [];
    },
    calViewers() {
      return this.viewers.length || 0;
    },
    checkUserSeen() {
      return this.detail.logs?.some(
        (x) => x.type_id == "read" && x.creator_id == this.meID
      );
    },
    checkCreator() {
      return this.detail.creator_id == this.meID;
    },
    checkHasFile() {
      return !!this.file.length;
    },
    disabled() {
      return !(this.loading_contents || this.checkHasFile || this.feedback);
    },
  },
  methods: {
    fetchContents() {
      this.$store.dispatch("helper.support_contents.apply-query", {
        "filter[support_id]": this.id,
      });
    },
    fetchDetail() {
      this.$store.dispatch("helper.supports.detail.refresh");
    },
    create() {
      let data = {
        support_id: this.id,
        content: this.feedback,
        attachment_file: this.file[0]?.file,
      };
      const dataClean = this.lodash.pickBy(data, this.lodash.identity);
      this.$store
        .dispatch("helper.support_contents.create", dataClean)
        .then(() => {
          this.feedback = "";
          this.file = [];
          this.fetchContents();
          this.fetchDetail();
        });
    },
    updateSupport(field, value) {
      return this.$store.dispatch("helper.supports.detail.update", {
        [field]: value,
      });
    },
    updateInfoSupport() {
      this.$store
        .dispatch(
          "helper.supports.detail.update",
          this.lodash.pickBy({
            deadline: this.deadlineUpdate,
          })
        )
        .then(() => {
          this.fetchDetail();
        })
        .finally(() => {
          this.infoEditing = false;
        });
    },
    updateSupportContent(id, field, value) {
      this.$store.dispatch("helper.support_contents.update", {
        id,
        attributes: {
          [field]: value,
        },
      });
    },
    getColor(value) {
      const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
      const index = alphabet.indexOf(value.charAt(0).toUpperCase());
      if (index >= 0 && index <= 3) return "bg-info";
      if (index >= 4 && index <= 7) return "bg-success";
      if (index >= 8 && index <= 11) return "bg-primary";
      if (index >= 12 && index <= 15) return "bg-danger";
      if (index >= 16 && index <= 19) return "bg-warning";
      if (index >= 20 && index <= 23) return "bg-secondary";
      if (index >= 24) return "bg-dark";
    },
    actionSupport(type) {
      this.actionLoading = true;
      this.$store
        .dispatch("helper.supports.detail." + type)
        .then(() => {
          this.fetchDetail();
        })
        .finally(() => {
          this.actionLoading = false;
        });
    },
    getUrl(path) {
      return process.env.VUE_APP_HELPER_SERVICE_HOST + "/files/" + path;
    },
    checkSeen(logs) {
      return (
        logs
          ?.filter((x) => x.type_id == "read")
          .some((x) => x.creator_id == this.meID) || true
      );
    },
    checkMimeType(type) {
      return ["image/png", "image/jpeg", "image/webp", "image/tiff"].includes(
        JSON.parse(type).mime_type
      );
    },
    updateFile(files) {
      if (files.length > 0) this.file = files;
      else this.file = [];
    },
  },
};
</script>

<style scoped>
* >>> textarea.form-control {
  height: 80px;
}
</style>
